import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  homeIcon,
  instagramIcon,
  calendarIcon,
  envelopeIcon,
  epkIcon,
} from '../Icons';

import './nav.sass';

class Nav extends Component {
  render() {
    return (
      <nav className="menu">
        <Link className="menu-item" to="/">
          {homeIcon}
        </Link>
        <Link className="menu-item" to="/calendar">
          {calendarIcon}
        </Link>
        <a
          href="https://www.instagram.com/dannylipsitzmusic"
          className="menu-item"
          rel="noopener noreferrer"
          target="_blank"
        >
          {instagramIcon}
        </a>
        <a
          className="menu-item"
          href="/danny-lipsitz-epk-2025.pdf"
          rel="noopener noreferrer"
          target="_blank"
        >
          {epkIcon}
        </a>
        <Link className="menu-item" to="/contact">
          {envelopeIcon}
        </Link>
      </nav>
    );
  }
}

export default Nav;
