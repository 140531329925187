import React, { Component } from 'react';
import Video from '../Video';
import Page from '../Page';

import './main.sass';

class Home extends Component {
  render() {
    let home;

    if (this.props.UI.isMobile) {
      home = (
        <section className="home">
          <Video
            title="brass tacks reel"
            src="https://youtube.com/embed/sE5wmzLZSq8"
          />
          <Page pageSlug="bio" />
        </section>
      );
    } else {
      home = (
        <section className="home">
          <Video
            className="wide-video"
            title="brass tacks reel"
            src="https://youtube.com/embed/sE5wmzLZSq8"
          />
          <Page pageSlug="bio" />
        </section>
      );
    }

    return home;
  }
}

export default Home;
