import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  homeIcon,
  instagramIcon,
  calendarIcon,
  envelopeIcon,
  bandcampIcon,
  epkIcon,
} from '../Icons';

class MobileNav extends Component {
  render() {
    return (
      <nav style={this.props.UI.mobileMenuStyle} className="mobile-menu">
        <Link onClick={this.props.menuOff} className="menu-item" to="/">
          {homeIcon}
        </Link>
        <Link onClick={this.props.menuOff} className="menu-item" to="/calendar">
          {calendarIcon}
        </Link>
        <Link
          onClick={this.props.menuOff}
          className="menu-item"
          to="/instagram"
        >
          {instagramIcon}
        </Link>
        <a
          onClick={this.props.menuOff}
          className="menu-item"
          href="/danny-lipsitz-epk-2025.pdf"
          rel="noopener noreferrer"
          target="_blank"
        >
          {epkIcon}
        </a>
        <a
          onClick={this.props.menuOff}
          className="menu-item"
          href="https://dannylipsitz.bandcamp.com/"
          rel="noopener noreferrer"
          target="_blank"
        >
          {bandcampIcon}
        </a>
        <Link onClick={this.props.menuOff} className="menu-item" to="/contact">
          {envelopeIcon}
        </Link>
      </nav>
    );
  }
}

export default MobileNav;
