import React from 'react';

import './icons.sass';

function iconStacker(icon) {
  const stacked = `fa fa-${icon} fa-stack-1x`;

  return (
    <span className="fa-stack fa-lg">
      <i className="fa fa-square-o fa-stack-2x" />
      <i style={{ marginTop: 2 }} className={stacked} />
    </span>
  );
}

export const homeIcon = iconStacker('home');
export const bandcampIcon = iconStacker('bandcamp');
export const envelopeIcon = iconStacker('envelope');
export const facebookIcon = iconStacker('facebook');
export const calendarIcon = iconStacker('calendar');
export const instagramIcon = iconStacker('instagram');
export const epkIcon = (
  <span className="fa-stack fa-lg">
    <i className="fa fa-square-o fa-stack-2x" />
    <span>
      <svg
        className="epk-icon"
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="100%"
        viewBox="0 0 600 600"
        xmlSpace="preserve"
      >
        <path
          // fill="#050505"
          opacity="1.000000"
          stroke="none"
          d="
M142.000153,377.304443 
  C163.472168,377.304474 184.444199,377.304474 205.790375,377.304474 
  C205.790375,389.024963 205.790375,400.413055 205.790375,412.113922 
  C155.509781,412.113922 105.278778,412.113922 54.658047,412.113922 
  C54.551945,410.489594 54.376144,409.052704 54.375980,407.615814 
  C54.367966,336.284119 54.420677,264.952332 54.272308,193.620972 
  C54.262936,189.116562 55.492039,188.144501 59.847069,188.159790 
  C106.678741,188.324097 153.511215,188.258438 200.343475,188.255753 
  C202.124939,188.255661 203.906387,188.255737 205.933273,188.255737 
  C205.933273,200.115616 205.933273,211.382065 205.933273,223.214294 
  C169.543274,223.214294 133.315048,223.214294 96.685173,223.214294 
  C96.685173,242.669678 96.685173,261.568146 96.685173,280.948639 
  C97.716400,281.069061 98.961578,281.341888 100.206665,281.341522 
  C165.537430,281.321411 230.868622,281.374847 296.198212,281.075897 
  C302.199493,281.048431 308.492371,279.535004 314.123444,277.353119 
  C325.980927,272.758575 332.705475,262.398590 332.272583,251.206818 
  C331.829346,239.748398 323.591797,229.245438 311.261017,225.352051 
  C306.407013,223.819397 301.182312,222.927322 296.095245,222.773010 
  C284.106781,222.409424 272.096436,222.819687 260.101654,222.580963 
  C256.191498,222.503159 254.910919,223.542160 254.972229,227.579575 
  C255.199921,242.575821 254.954834,257.579010 255.152725,272.576080 
  C255.201950,276.306396 254.149719,277.461975 250.365295,277.377502 
  C239.370987,277.132294 228.367355,277.240265 217.368256,277.323792 
  C214.839783,277.342987 213.307007,277.022156 213.318466,273.881256 
  C213.420593,245.884171 213.402618,217.886673 213.436829,189.889297 
  C213.437195,189.587250 213.661453,189.285461 213.919861,188.633881 
  C214.847183,188.523193 215.945343,188.278458 217.043655,188.277649 
  C244.543015,188.257385 272.042572,188.204285 299.541687,188.286270 
  C315.371216,188.333466 330.167053,192.065140 343.520966,200.950195 
  C346.176056,202.716751 347.317566,204.534042 347.304413,207.786377 
  C347.183929,237.618530 347.184692,267.451569 347.303040,297.283752 
  C347.315948,300.534943 346.211975,302.379517 343.500610,304.098877 
  C329.628845,312.895569 314.291443,315.758026 298.198761,315.760193 
  C232.866943,315.768921 167.534973,315.819977 102.203629,315.644226 
  C97.293335,315.631012 96.110558,316.976135 96.193710,321.750244 
  C96.483902,338.411194 96.287498,355.080688 96.276321,371.746918 
  C96.275215,373.386200 96.276176,375.025482 96.276176,377.304443 
  C111.610428,377.304443 126.555283,377.304443 142.000153,377.304443 
z"
        />
        <path
          // fill="#030303"
          opacity="1.000000"
          stroke="none"
          d="
  M537.105347,191.928757 
  C510.552246,225.532486 484.289856,258.892853 457.840576,292.104401 
  C455.260590,295.344055 455.216858,297.252197 457.847839,300.474731 
  C486.909241,336.070953 515.802490,371.804443 544.730713,407.509277 
  C545.232300,408.128235 545.603821,408.852509 546.941833,410.942688 
  C541.871948,410.942688 537.855042,410.823273 533.847595,410.966248 
  C523.860596,411.322388 513.870544,411.680237 503.897522,412.293610 
  C500.693604,412.490631 498.800995,411.368835 496.816284,408.915619 
  C474.930603,381.863708 452.911469,354.919739 430.921051,327.952606 
  C429.890594,326.688904 428.790283,325.482117 427.445435,323.928802 
  C417.789368,334.025146 408.285034,343.797943 399.047577,353.816803 
  C397.801453,355.168365 397.457397,357.754517 397.442505,359.774506 
  C397.325928,375.601318 397.245300,391.431824 397.498199,407.255676 
  C397.566223,411.511139 396.217743,412.586182 392.125336,412.484283 
  C381.135925,412.210724 370.135406,412.384705 359.139282,412.381195 
  C357.520477,412.380676 355.901642,412.381134 353.963745,412.381134 
  C353.963745,337.586243 353.963745,263.349304 353.963745,188.670532 
  C368.212799,188.670532 382.544556,188.670532 397.350586,188.670532 
  C397.350586,225.239105 397.350586,261.630859 397.350586,298.903229 
  C398.761749,297.806152 399.589905,297.382019 400.119354,296.719238 
  C428.172791,261.598907 456.180878,226.442261 484.302612,191.376755 
  C485.477417,189.911850 487.676300,188.415329 489.418335,188.391144 
  C505.574738,188.166763 521.735535,188.260590 537.895081,188.284790 
  C538.161316,188.285187 538.427185,188.570190 539.043091,188.922501 
  C538.472534,189.836288 537.908203,190.740082 537.105347,191.928757 
z"
        />
        <path
          // fill="#060606"
          opacity="1.000000"
          stroke="none"
          d="
  M213.391937,364.999817 
  C213.391998,349.731628 213.391998,334.963470 213.391998,319.906128 
  C227.395950,319.906128 240.956116,319.906128 254.795227,319.906128 
  C254.795227,350.658630 254.795227,381.196472 254.795227,412.393066 
  C248.799469,412.393066 243.022232,412.392700 237.244980,412.393158 
  C230.754608,412.393677 224.256210,412.201996 217.777069,412.472198 
  C214.201385,412.621307 213.262756,411.434601 213.310181,407.935760 
  C213.501846,393.792694 213.391037,379.645477 213.391937,364.999817 
z"
        />
      </svg>
    </span>
  </span>
);
